<template>
  <div class="loading">
    <!--加载中...-->
  </div>
</template>

<script>
    export default {
        name: "home-loading"
    }
</script>

<style class="less" scoped>
  .loading {
    text-align: center;
  }
</style>
