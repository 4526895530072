<template>
    <div class="HomeProduct">
      <HomePanel title="商品详情" subTitle="录入资料 确认付款">
        <!-- 面板内容 -->
        <Transition name='fade' ref="target">
          <div class="goods-detail" v-if="item">
            <div class="left">
              <img src="../../assets/images/placeholder.png" />
              <!--<img :src="item.picture" alt="" />-->
              <p class="name">商品型号 {{ item.name }}</p>
              <p class="price">&yen;{{ item.price/100 }}</p>
            </div>
            <div class="main">
              <div class="body">
                <p class="title"><span>购买主体：</span><input type="text" placeholder="必填，与签约主体一致" v-model="order.buyBody"/></p>
                <p class="title"><span>付款人手机号：</span><input type="text" placeholder="必填，用于订单查询和退款" v-model="order.contactInfo"/></p>
                <p class="title"><span>合同编号：</span><input type="text" placeholder="必填，合同编号" v-model="order.contractNo"/></p>
                <p class="title"><label for="agentNo">代理人编号：</label>
                  <select id="agentNo" v-model="order.agentNo" name="agent">
                    <option value="">请选择</option>
                    <option value="ag01">代理人1号</option>
                    <option value="ag02">代理人2号</option>
                  </select>
                </p>
                <p class="title"><span>SN码：</span><input type="text" placeholder="非必填" v-model="order.sn"/></p>
              </div>
              <div class="payment">
                <p class="title"><span>选择付款方式：</span>
                  <input type="radio" id="b2b" value="gateway_b2b" v-model="order.paymentType"/> <label for="b2b">企业账户</label>&nbsp;&nbsp;
                  <input type="radio" id="ali" value="alipay_scan" v-model="order.paymentType"/><label for="ali">支付宝</label> &nbsp;&nbsp;
                  <input type="radio" id="wx" value="wechat_scan" v-model="order.paymentType"/><label for="wx">微信 </label>
                </p>
              </div>
              <button @click="submit">确认支付</button>
              <el-dialog
                v-model="dialogVisible"
                :title="qrTitle"
                :before-close="handleClose"
                width="20%"
                customClass="customWidth"
              >
                  <qrcode-vue :value='qrCodeUrl' :size='200' level='H' />
                  <!--<template #footer>-->
                    <!--<span class="dialog-footer">-->
                      <!--<el-button @click="dialogVisible = false">Cancel</el-button>-->
                      <!--<el-button type="primary" @click="dialogVisible = false"-->
                      <!--&gt;Confirm</el-button>-->
                    <!--</span>-->
                  <!--</template>-->
                </el-dialog>
            </div>
          </div>
          <HomeLoading v-else></HomeLoading>
        </Transition>
      </HomePanel>
    </div>
</template>

<script>
  import { ref, reactive, onBeforeUnmount } from "vue";
  import HomePanel from "./../home/components/home-panel.vue";
  import HomeLoading from "./../home/components/home-loading.vue";
  import {uselazyData} from '@/hooks/index'
  import { findGoodsById } from "@/api/home";
  import {useRouter} from "vue-router";
  import {alipayOrder, wechatPayOrder, gateWayPayOrder, findOrderByTrace} from "../../api/home";
  import { ElMessage, ElMessageBox } from 'element-plus'
  import QrcodeVue from 'qrcode.vue'

  export default {
    name: "Product",
    components: { HomePanel, HomeLoading, QrcodeVue },
    setup() {
      const router = useRouter()
      let goodsId = router.currentRoute.value.params["id"];
      const item = ref(null);
      findGoodsById(goodsId).then(data => {
        console.log('goods detail ', data)
        item.value = data.data
      });
      // 补充部分
      const qrCodeUrl = ref('')
      const qrTitle = ref('')
      const dialogVisible = ref(false)
      const target = ref(null)
      const order = reactive({buyBody: '', contactInfo: '', contractNo:'', agentNo: '', sn: '', paymentType:'gateway_b2b'})
      const submit = () => {
        let postData = {...order, tradeamt: item.value.price, goodsId, goodsModel: item.value.name, body: item.value.name}
        console.log('build post body ', postData)
        // validate form
        if (!order.buyBody || order.buyBody.length > 30) {
          ElMessage.error('请输入正确的购买主体')
          return
        }
        if (!order.contractNo || order.contractNo.length > 12) {
          ElMessage.error('请输入正确的合同编号')
          return
        }
        if (!order.agentNo) {
          ElMessage.error('请选择对应的代理商')
          return
        }
        let reg= /^\d{11}$/;
        if (!reg.test(order.contactInfo)) {
          ElMessage.error('请输入正确的手机号')
          return
        }
        switch (order.paymentType) {
          case 'gateway_b2b':
            // gateWayPayOrder(postData).then(data => {
            //   console.log('gateway_b2b feedback ', data)
            //   // getOrderstate({trace: data.trace, type: 'gateway_b2b'})
            // })
            // Test
            getOrderstate('00049620211026105913915679')
            break
          case 'alipay_scan':
            alipayOrder(postData).then(data => {
              console.log('alipay_scan feedback ', data)
              if (data.code === '1') {
                qrTitle.value = '支付宝支付'
                qrCodeUrl.value =  data.data.resultContent
                dialogVisible.value = true
                // 开启定时监听
                getOrderstate(data.trace)
              } else {
                console.log('alipay_scan error ', data.message)
                ElMessage({
                  showClose: true,
                  message: '出错了：'+ data.message,
                  type: 'error',
                })
              }
            })
            break
          case 'wechat_scan':
            wechatPayOrder(postData).then(data => {
              console.log('wechat_scan feedback ', data)
              if (data.code === '1') {
                qrTitle.value = '微信支付'
                qrCodeUrl.value =  data.data.resultContent
                dialogVisible.value = true
                // 开启定时监听
                console.log(data.trace)
                getOrderstate(data.trace)
              } else {
                console.log('wechat_scan error ', data.message)
                ElMessage({
                  showClose: true,
                  message: '出错了：'+ data.message,
                  type: 'error',
                })
              }
            })
            break
          default:
            console.log('not found pay method')
        }
      }


      const handleClose = (done) => {
        ElMessageBox.confirm('确定要退出付款吗?')
          .then(() => {
            done()
          })
          .catch(() => {
            // catch error
          })
      }
      const watchtimes = ref('')
      const getOrderstate = (trace)=>{
        let num = 0
        watchtimes.value = setInterval(()=>{ //创建一个全局的定时器
          num ++
          findOrderByTrace({trace: trace})
            .then(data => {
              console.log(data)
              if(data.code === '1' && data.data.status === 'success'){
                console.log('ready route ')
                router.push({
                  path: '/notify/success'
                })
                clearInterval(watchtimes.value) //别忘记关闭定时器，否则会一直调这个接口
              } else if(data.code === '1' && data.data.status === 'fail'){
                console.log('ready route ')
                router.push({
                  path: '/notify/fail'
                })
                clearInterval(watchtimes.value)
              }})

          console.log("curr ", num, trace)
          if(num === 10){ //这里是判断num++到500的情况下用户还没有支付则自动关闭定时器和二维码
            dialogVisible.value = false
            clearInterval(watchtimes.value)
          }
        },1500)
      }
      onBeforeUnmount(() => {
        console.log('销毁定时器')
        clearInterval(watchtimes.value)
      })

      return { item, target, submit, order,
        dialogVisible,
        handleClose,
        qrTitle,
        qrCodeUrl,
        watchtimes };
    }

  }
</script>

<style lang="less"  scoped>
  .goods-detail {
    display: flex;
    flex-direction: row;
    margin: 10px auto;
    .left {
      width: 40%;
      img {
        margin-top: 10px;
        margin-left: 30px;
        width: 250px;
        height: 52px;
      }
      p {
        font-size: 18px;
        padding: 12px 30px 0 30px;
      }
    }
    .main {
      border-left: 1px solid rgba(225, 225, 225, 0.5);
      padding-left: 40px;
      .body {
        .title {
          font-size: 18px;
          color: #27BA9B;
          display: flex;
          flex-direction: row;
          margin-top: 15px;
          span {
            display: block;
            width: 150px;
          }
          label {
            display: block;
            width: 150px;
          }
          select {
            font-size: 18px;
            width: 250px;
            color: #27BA9B;
          }
          input {
            font-size: 18px;
            width: 250px;
          }
        }
      }
      .payment {
        margin-top: 20px;
        border: 1px solid rgba(0, 23, 23, 0.1);
        border-radius: 5px;
        padding: 20px 30px 20px 5px;
        label, span {
          /*display: block;*/
          width: 150px;
          font-size: 18px;
          color: #999;
        }
      }
      button {
        margin: 50px 0 20px 30px;
        padding: 5px 0;
        font-size: 20px;
        width: 200px;
        text-align: center;
        background-color: blueviolet;
        border: 0;
        color: white;
        border-radius: 5px;
      }
    }

    .price {
      color: @priceColor;
    }

    .dialog-header {
      height: 200px;
      width: 300px;
      text-align: center;
      margin: 0 auto;
      .amt {
        color: red;
        font-size: 40px
      }
    }
    .qrcode {
      margin: 0 auto;
    }
    .dialog-footer {

    }
    .customWidth {
      width: 200px;
    }
  }

</style>
